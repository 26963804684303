@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  font-weight: 600;
}

h4,
h5,
h6,
strong,
b {
  font-weight: 600;
}

a {
  color: #222222;
  text-decoration: none;
}

.hover:hover,
.btn-link:hover {
  cursor: pointer;
  background-color: #ebebeb;
}

.badge {
  vertical-align: middle !important;
}
/* user menu */
.usermenu svg {
  width: 14px !important;
}

/* login */
.login-box,
.register-box {
  width: 400px;
}

/* table */
.table th {
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.no-break {
  white-space: nowrap;
  word-wrap: normal;
}

strong.rname {
  font-weight: 500;
}

/* widget */
.list-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* tags */
.suggested-tag {
  font-size: 13px !important;
}
.suggested-tag:hover {
  cursor: pointer;
  background: #ebebeb !important;
}
/* suggested tag box from canvas */
.tags-lbox {
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.tags-lbox::-webkit-scrollbar {
  width: 5px;
}
.tags-lbox::-webkit-scrollbar-track {
  background-color: #ebebeb;
}
.tags-lbox::-webkit-scrollbar-thumb {
  background-color: #666666;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.cursor-pointer {
  user-select: none;
  cursor: pointer;
}

/* small list */
.small-thumb {
  max-height: 64px;
}

/* rechart */
.recharts-legend-item-text {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
}

.sidebar {
  width: 60px;
}

.sidebar.show {
  width: 200px;
}
.sidebar .sidebar-content {
  width: 60px;
}
.sidebar.show .sidebar-content {
  width: 200px;
}

@media (max-width: 767.98px) {
  .list-title {
    white-space : inherit !important;
  }
  .sidebar {
    width: 200px;
  }
  .sidebar .item-name {
    display: block !important;
    opacity: 1 !important;
  }
  .sidebar.show {
    width: 60px;
  }
  .sidebar.show .item-name {
    display: none !important;
    opacity: 0 !important;
  }
  .sidebar .sidebar-content {
    width: 200px;
  }
  .sidebar.show .sidebar-content {
    width: 60px;
  }
}