.wrapper {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-bottom: 2rem;
}

.pdf {
  width: 595px;
  /* min-height: 842px; */
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.header {
  margin-bottom: 2.5rem;
}

.headerTitle {
  min-width: 150px;
  font-weight: 600;
}

.headerContent {
  min-width: 250px;
}
