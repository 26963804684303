.sidebar {
  overflow-x: hidden;
  flex: 0 0 auto;
  transition: all 0.3s;
}

.wrapper {
  position: fixed;
  height: 100vh;
  border-right: 1px solid var(--border-gray);
  z-index: 11;
  overflow-x: hidden;
  background: #fff;
  transition: all 0.3s;
}

.logoWrapper {
  padding: 8px 24px;
  min-height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-gray);
}

.logoImg {
  max-height: 25px;
}

.itemLink {
  display: flex;
  align-items: center;
  color: #000;
  width: 100%;
  padding: 8px 24px;
}
