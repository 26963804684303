.topbar {
  border-bottom: 1px solid var(--border-gray);
  min-height: 55px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  /* box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1); */
  background: #fff;
  z-index: 20;
  padding: 0 16px;
  margin-left: 0px;
  width: calc(100%);
  transition: all 0.3s;
}

.topbar.show {
  width: calc(100% - 200px);
  margin-left: 200px;
}

.toggleBtn {
  background: white;
  border: none;
}

.userMenu {
  display: flex;
  align-items: center;
}

@media (max-width: 767.98px) {
  .topbar {
    width: calc(100% - 200px);
    margin-left: 200px;
  }

  .topbar.show {
    width: calc(100% - 60px);
    margin-left: 60px;
  }
}