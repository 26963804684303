:root {
  --main-text-color: #222222;
  --primary-color: #16a34a;
  --border-gray: #eee;
  --bg-eps: #bb8d46;
  --bg-ai: #ca6510;
  --bg-svg: #ff9a01;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.wrapper {
  width: 100%;
}
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.format {
  border-radius: 5px;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.format--ai {
  /* color: #fff; */
  color: var(--bg-ai);
}

.format--eps {
  /* color: var(--bg-eps); */
  color: var(--bg-eps);
}

.format--svg {
  /* color: #fff; */
  color: var(--bg-svg);
}

.cursor--pointer {
  cursor: pointer;
}

.img-preview {
  max-width: 100%;
  object-fit: contain;
}

.light-box__content {
  max-height: 500px;
}

.light-box__img {
  object-fit: contain;
  width: 100%;
}

.min-w-100 {
  min-width: 100%;
}

/* resource grid */
.rgrid-sorter,
.check-action button {
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.check-action button {
  text-decoration: none;
}

.rgrid-item .item-check {
  z-index: 999;
  top: 10px;
  right: 10px;
}
.rgrid-item .rname {
  line-height: 0.5;
}
.rgrid-item .rcontent {
  border-color: transparent !important;
}
.rgrid-item .rcontent.checked {
  border-color: #ebebeb !important;
}
.rgrid-item:hover .rcontent {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.rgrid-item .img-preview {
  max-width: 100%;
  background-color: #f2f2f2;
}
.rgrid-item:hover .rdetail {
  z-index: 990;
  display: block !important;
}
.rgrid-item .item-action {
  width: 100%;
}
.rgrid-item .item-action .btn:hover {
  background-color: #ebebeb;
}
.rgrid-item:hover .item-action {
  display: flex !important;
  z-index: 998;
  background: #fff;
}
.rgrid-item .rdetail {
  /* background: rgba(255,255,255,.85); */
  background: #fff;
  width: 100%;
}

.w-20 {
  width: 20%;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
