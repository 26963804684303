.mainContent {
  width: calc(100% - 60px);
  position: relative;
  margin-top: 55px;
  margin-bottom: 24px;
  padding: 1.5rem;
  transition: width 0.3s;
}

.mainContent.show {
  width: calc(100% - 200px);
}

@media (max-width: 767.98px) {
  
  .mainContent {
    width: calc(100% - 200px);
  }
  
  .mainContent.show {
    width: calc(100% - 60px);
  }

}